.c-banner {
	$root: &;

	@include g-page-designer-vertical_alignments;

	display: flex;
	width: 100%;

	&.m-vertical_fill {
		@include media(sm) {
			align-items: flex-start;
		}
	}

	&.m-vertical_fill-sm {
		@include media(sm) {
			align-items: stretch;
		}
	}

	&-inner {
		@include g-grid;

		width: 100%;

		#{$root} & {
			grid-gap: 0;
		}
	}

	&-caption {
		@include g-page-designer-alignments;
		@include g-page-designer-sm_self_alignments;

		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;
		padding: 60px grid-margin(xl);

		@include media(lg) {
			padding-left: grid-margin(lg);
			padding-right: grid-margin(lg);
		}

		@include media(md) {
			padding-left: grid-margin(md);
			padding-right: grid-margin(md);
		}

		@include media(sm) {
			padding: 48px grid-margin(sm);
		}

		&.m-beneath_image {
			display: flex;

			@include media(md-up) {
				height: 100%;
				padding: 0;
			}

			#{$root}_content {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&.m-beneath_bottom {
			grid-row: 2;
			padding: 0;

			#{$root}_content {
				width: 100%;
			}
		}

		&.m-beneath_right {
			flex-direction: row-reverse;
		}

		&.m-sm-beneath_image {
			@include media(sm) {
				grid-row: 2;
				padding: 0;

				#{$root}_content {
					width: 100%;
				}
			}
		}

		.l-plp_grid-banner & {
			padding: 44px 16px;
		}

		.l-plp_grid-banner:nth-of-type(2) & {
			padding: 24px;
		}

		.l-grid_layout.m-centered & {
			@include media(lg-up) {
				padding-left: 20px;
				padding-right: 20px;
			}

			@include media(md) {
				padding-left: 4px;
				padding-right: 4px;
			}

			&.m-beneath_image {
				padding: 0;
			}
		}

		img {
			object-position: var(--content-obj-position);
		}
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(2.26, 1));

		background-color: transparent;
		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;
		padding-bottom: var(--bg-padding, aspect-ratio(2.26, 1));
		z-index: 0;

		@include media(sm) {
			padding-bottom: var(--bg-padding-mobile, --bg-padding);
		}

		&.m-fixed_height-md_up {
			@include media(md-up) {
				min-height: var(--min-height, auto);
				padding-bottom: 0;
			}
		}

		&.m-fixed_height-sm {
			@include media(sm) {
				min-height: var(--min-height-mobile, auto);
				padding-bottom: 0;
			}
		}

		img,
		video {
			@include g-image_container(_img);
		}

		img {
			object-position: var(--img-obj-position);

			@include media(sm) {
				object-position: var(--img-sm-obj-position, var(--img-obj-position));
			}
		}
	}

	&-disable_mobile {
		@include media(sm) {
			display: none;
		}
	}

	.b-text_block {
		position: relative;
		width: 100%;
	}
}
