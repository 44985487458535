.l-grid_layout {
	$root: &;

	@include g-section_holder;
	@include g-page-designer-component-paddings('.l-grid_layout-content');

	background-color: var(--bg-layout-color);
	background-position: var(--bg-image-position);
	background-repeat: var(--bg-image-repeat);
	background-size: var(--bg-image-size);

	@include media(lg-up) {
		background-image: var(--bg-image-desktop);
	}

	@include media(md) {
		background-image: var(--bg-image-tablet);
	}

	@include media(sm) {
		background-image: var(--bg-image-mobile);
	}

	&.m-full_bleed,
	&.m-full_with_centered {
		max-width: $global-site-width-max;
		padding-left: 0;
		padding-right: 0;
	}

	&.m-2_8_2 {
		@include media(sm) {
			#{$root}-item.m-lg_2 {
				display: none;
			}
		}
	}

	&-content {
		@include g-grid;

		.m-full_with_centered & {
			@include g-section_holder;
		}

		&.m-hide_gutters {
			grid-gap: 0;
		}
	}

	&-item { // stylelint-disable-line
		$sm-cols: grid-columns('sm');
		$md-cols: grid-columns('md');
		$lg-cols: grid-columns('lg');

		@include g-page-designer-alignments;
		@include g-page-designer-bottom_margins;

		max-width: 100%;

		&_in {
			display: flex;

			> div,
			> section {
				width: 100%;
			}
		}

		&_in:only-child {
			height: 100%;
		}

		&:not(.m-vertical_top):not(.m-vertical_middle):not(.m-vertical_bottom)[data-items='one'] {
			align-self: auto;
		}

		&.m-title {
			grid-column: span 6;
			order: -2;

			@include media('md-up') {
				grid-column: span 12;
			}
		}

		&.m-fade_in > div,
		&.m-fade_in > section {
			opacity: 0;
			transform: translateY(10%);
			transition: 1.2s ease-out;
			transition-property: transform, opacity;
		}

		&.m-bounce {
			overflow: hidden;
		}

		&.m-bounce > div,
		&.m-bounce > section {
			opacity: 0;
			transform: scale(0.8);
		}

		&.m-fade_in.m-animated > div,
		&.m-fade_in.m-animated > section {
			opacity: 1;
			transform: translateY(0);
		}

		&.m-bounce.m-animated > div,
		&.m-bounce.m-animated > section {
			animation-duration: 1.2s;
			animation-fill-mode: both;
			animation-name: growIn;
		}

		@for $column from $sm-cols through 1 {
			&.m-sm_#{$column} {
				grid-column: span $column;
			}
		}

		@for $column from $md-cols through 1 {
			@include media('md-up') {
				&.m-md_#{$column} {
					grid-column: span $column;
				}
			}
		}

		@for $column from $lg-cols through 1 {
			@include media('lg-up') {
				&.m-lg_#{$column} {
					grid-column: span $column;
				}
			}
		}
	}

	&-item_title {
		padding-bottom: 20px;

		@include media(sm) {
			padding-bottom: 16px;
		}
	}

	&.m-custom {
        @include g-page-designer-component-paddings('.m-title');

		#{$root}-item {
			display: flex;

            &.m-title {
                padding-bottom: 0;
            }

            &.m-title + .l-grid_layout-content {
                padding-top: 0;
            }
		}

		#{$root}-item > div {
			width: 100%;
		}
	}

	&.m-mobile_reversed {
		@include media(sm) {
			#{$root}-item:last-child {
				order: -1;
			}
		}
	}

	&.m-3_9,
	&.m-4_8,
	&.m-3_3_6 {
		@include media(sm) {
			#{$root}-item:last-child {
				order: -1;
			}
		}

		&.m-mobile_reversed #{$root}-item:last-child {
			order: 1;
		}
	}
}

.page-section {
    @include g-page-designer-component-paddings('.l-grid_layout-content');
}
